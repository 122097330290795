<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-sidebar-wrap"
  >
    <div class="w-sidebar-main">
      <div class="clearfix"></div>
      <div class="w-used-function">
        <span>常用功能</span>
        <span
          class="w-mangage"
          @click="toCommonListFunc()"
        >管理</span>
      </div>
      <div
        v-if="oftenList && oftenList.length > 0"
        class="w-used-wrap"
      >
        <div
          class="w-used-item"
          v-for="item in oftenList"
          :key="item.id"
          @click="toPage(item)"
        >
          <span :class="['icon-' + item.sign]"></span>
          <div class="w-item-name">
            <p class="w-p1">{{ item.cnname2 }}</p>
            <p class="w-p2">{{ item.remark }}</p>
          </div>
        </div>
        <div
          class="w-used-item"
          v-if="oftenList && oftenList.length < 6"
          @click="toCommonListFunc()"
        >
          <span class="el-icon-circle-plus-outline"></span>
          <div class="w-add-text">添加更多常用功能</div>
        </div>
      </div>
      <div v-else>
        <div
          class="w-is-not-data"
          v-if="oftenList && oftenList.length === 0"
        >
          <p class="w-not-tips">您当前还未添加常用功能入口</p>
          <p
            class="w-add-used-btn cursor"
            @click="toCommonListFunc()"
          >添加常用功能</p>
        </div>
      </div>
      <span class="w-app-title-name">应用中心</span>
      <div class="w-app-list-wrap">
        <ul>
          <li
            v-for="item in modulesList"
            :key="item.id"
            @click="toModules(item)"
          >
            <div class="w-app-img">
              <img
                :src="item.img"
                alt
              />
            </div>
            <div
              class="w-app-title"
              :class="[item.id === 188 ? 'grey-color1' : '']"
            >{{ item.cnname }}</div>
            <div
              class="w-app-text"
              :class="[item.id === 188 ? 'grey-color2' : '']"
            >{{ item.remark }}</div>
          </li>
        </ul>
        <i class="w-shadow"></i>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      loading: true,
      oftenList: [],
      modulesList: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    },
    winshangURL () {
      let url = ''
      if (window.location.href.indexOf('http://') > -1) {
        url = 'http://' + document.domain
      } else {
        url = 'https://' + document.domain
      }
      return url
    }
  },
  created () {
    // document.body.style.overflowY = 'hidden'
    this.getOftenConfigFunc()
    this.getModularListFunc()
  },
  methods: {
    isIE () { // ie?
      if (!!window.ActiveXObject || 'ActiveXObject' in window) { return true } else { return false }
    },
    toPage (item) {
      localStorage.removeItem('saveScreen')
      if (item.sign === 'zsgh_xmzt') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/work' }, '*')
        window.open(this.winshangURL + '/#/work', '_blank')
      } else if (item.sign === 'zsgh_zsmb') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/investmentAimMange' }, '*')
        window.open(this.winshangURL + '/#/investmentAimMange', '_blank')
      } else if (item.sign === 'zsgh_pwzs') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/berthInvestmentManage' }, '*')
        window.open(this.winshangURL + '/#/berthInvestmentManage', '_blank')
      } else if (item.sign === 'zsgh_pwch') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/detachingShops' }, '*')
        window.open(this.winshangURL + '/#/detachingShops', '_blank')
      } else if (item.sign === 'zsss_zsgj') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/implement' }, '*')
        window.open(this.winshangURL + '/#/implement', '_blank')
      } else if (item.sign === 'zsss_spqy') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/shopsSinged' }, '*')
        window.open(this.winshangURL + '/#/shopsSinged', '_blank')
      } else if (item.sign === 'zsss_zsbg') {
        // 招商报告查询
        window.parent.postMessage({ toURL: this.winshangURL + '/#/merchantReports' }, '*')
        window.open(this.url + '/#/merchantReports', '_blank')
      } else if (item.sign === 'zsgh_ghfx') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/investmentPlanAnalysis?type=1' }, '*')
        window.open(this.url + '/#/investmentPlanAnalysis?type=1', '_blank')
      } else if (item.sign === 'zsss_ssfx') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/implementationAnalysis?type=1' }, '*')
        window.open(this.url + '/#/implementationAnalysis?type=1', '_blank')
      } else if (item.sign === 'zsss_jdgk') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/implement' }, '*')
        window.open(this.url + '/#/implement')
      } else if (item.sign === 'zsss_ksgl') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/visualization' }, '*')
        window.open(this.url + '/#/visualization')
      } else if (item.sign === 'zsss_pwzs') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/berthInvestmentManage' }, '*')
        window.open(this.url + '/#/berthInvestmentManage')
      } else if (item.sign === 'zsss_ppzs') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/investmentFollowManage' }, '*')
        window.open(this.url + '/#/investmentFollowManage')
      } else if (item.sign === 'zsss_zsjb') {
        window.parent.postMessage({ toURL: this.winshangURL + '/#/merchantReports' }, '*')
        window.open(this.url + '/#/merchantReports')
      } else {
        window.parent.postMessage({ toURL: item.url + '&tokenval=' + this.token }, '*')
        window.open(item.url + '&tokenval=' + this.token, '_blank')
      }
      this.ifIEdoSth()
    },
    ifIEdoSth () {
      if (document.domain.indexOf('local') !== -1 || document.domain.indexOf('prewbms') !== -1) {
        if (this.isIE()) {
          window.location.reload(true)
        }
      }
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data
          this.loading = false
        })
    },
    toModules (item) {
      localStorage.removeItem('saveScreen')
      if (item.id === 188) {
        if (window.location.href.indexOf('gdwscrm') > -1) {
          window.parent.postMessage({ popup: true, content: '持续升级中，敬请期待！' }, '*')
        } else {
          this.$message({
            type: 'warning',
            message: '持续升级中，敬请期待！'
          })
        }
      } else if (item.id === 7) {
        if (item.flag) {
          const res = this.user.confIds.filter(item => {
            return item.id === 209 || item.id === 210 || item.id === 211 || item.id === 212 || item.id === 213
          })
          if (res.length === 0) {
            if (window.location.href.indexOf('gdwscrm') > -1) {
              window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
            return false
          } else {
            const pathList = [
              'work',
              'leasingStrategy',
              'leasingPlan',
              'planningProject',
              'leasingAnalysis'
            ]
            res.sort((a, b) => {
              return a.id - b.id
            })
            window.parent.postMessage({ toURL: this.winshangURL + '/#/' + pathList[res[0].id - 209] }, '*')
            window.location.href = this.url + '/#/' + pathList[res[0].id - 209]
            if (this.isIE() && res[0].id === 209) {
              this.ifIEdoSth()
            }
          }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      } else if (item.id === 8) {
        if (item.flag) {
          const res8 = this.user.confIds.filter(item => {
            return item.id === 214 || item.id === 215 || item.id === 216 || item.id === 217 || item.id === 218
          })
          if (res8.length === 0) {
            if (window.location.href.indexOf('gdwscrm') > -1) {
              window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
            return false
          } else {
            const pathList8 = [
              'implement',
              'visualization',
              'berthInvestmentManage',
              'investmentFollowManage',
              'merchantReports'
            ]
            res8.sort((a, b) => {
              return a.id - b.id
            })
            window.parent.postMessage({ toURL: this.winshangURL + '/#/' + pathList8[res8[0].id - 214] }, '*')
            window.location.href = this.url + '/#/' + pathList8[res8[0].id - 214]
            if (this.isIE() && res8[0].id === 214) {
              this.ifIEdoSth()
            }
          }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      } else if (item.id === 128) {
        if (item.flag) {
          if (item.userFlag) {
            window.parent.postMessage({ toURL: this.$shulieDomain + 'dashboard&token=' + this.token, isOpen: true }, '*')
            window.open(this.$shulieDomain + 'dashboard&token=' + this.token, '_blank')
          } else {
            if (window.location.href.indexOf('gdwscrm') > -1) {
              window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
            return false
          }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      } else if (item.id === 137) {
        if (item.flag) {
          if (item.userFlag) {
            window.parent.postMessage({ toURL: this.$shulieDomain + 'storyboard&token=' + this.token, isOpen: true }, '*')
            window.open(this.$shulieDomain + 'storyboard&token=' + this.token, '_blank')
          } else {
            if (window.location.href.indexOf('gdwscrm') > -1) {
              window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
            return false
          }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      } else if (item.id === 231) {
        if (item.flag) {
          const res = this.user.confIds.filter(item => {
            return [
              231,
              232,
              233,
              234,
              235,
              236,
              237,
              238,
              239
            ].indexOf(item.id) !== -1
          })
          if (res.length === 0) {
            this.$message({
              type: 'warning',
              message: '暂无权限访问此页！'
            })
            return false
          } else {
            const pathList = [
              'workOrderCenter',
              'workSetting',
              'teamManagement'
            ]
            let key = 0
            const list = res.map(item => {
              return item.id
            })
            if (
              list.indexOf(232) !== -1 ||
              list.indexOf(235) !== -1 ||
              list.indexOf(236) !== -1 ||
              list.indexOf(237) !== -1 ||
              list.indexOf(238) !== -1 ||
              list.indexOf(239) !== -1
            ) {
              key = 0
            } else if (list.indexOf(233) !== -1) {
              key = 1
            } else if (list.indexOf(234) !== -1) {
              key = 2
            }
            window.parent.postMessage({ toURL: this.winshangURL + '/#/' + pathList[key] }, '*')
            window.open(this.winshangURL + '/#/' + pathList[key], '_blank')
            if (this.isIE() && key === 0) {
              this.ifIEdoSth()
            }
          }

          // if (item.userFlag) {
          //   window.parent.postMessage({ toURL: this.winshangURL + '/#/workOrderCenter' }, '*')
          //   window.open(this.winshangURL + '/#/workOrderCenter', '_blank')
          // } else {
          //   if (window.location.href.indexOf('gdwscrm') > -1) {
          //     window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
          //   } else {
          //     this.$message({
          //       type: 'warning',
          //       message: '抱歉，此账号暂无权限！'
          //     })
          //   }
          //   return false
          // }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      } else {
        if (item.flag) {
          if (item.userFlag) {
            window.parent.postMessage({ toURL: this.$gaodeDomain + '?menuCode=' + item.id + '&token=' + this.token }, '*')
            window.location.href = this.$gaodeDomain + '?menuCode=' + item.id + '&tokenval=' + this.token
          } else {
            if (window.location.href.indexOf('gdwscrm') > -1) {
              window.parent.postMessage({ popup: true, content: '抱歉，此账号暂无权限！' }, '*')
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
            return false
          }
        } else {
          if (window.location.href.indexOf('gdwscrm') > -1) {
            window.parent.postMessage({ popup: true, content: '抱歉，集团暂未开通此应用模块！' }, '*')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
          return false
        }
      }
    },
    getOftenConfigFunc () {
      this.axios.post(api.getUserOftenConfigList)
        .then((res) => {
          this.oftenList = res.data.data
        })
    },
    toCommonListFunc () {
      window.parent.postMessage({ toURL: this.winshangURL + '/#/commonList' }, '*')
      window.location.href = this.winshangURL + '/#/commonList'
      // if (document.domain.indexOf('wbmscrm') > -1) {
      // } else {
      //   this.$router.push({
      //     path: '/commonList'
      //   })
      // }
      // window.open(href.href, '_blank')
    }
  },
  destroyed () {
    document.body.style.overflowY = 'auto'
  }
}
</script>

<style lang="stylus" scoped>
.w-sidebar-wrap
  position absolute
  left 0
  top 0px
  // width 516px
  height 100%
  overflow-y auto
  background #272930
  box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
  border-radius 3px 0px 3px 3px
  z-index 111
  // padding-bottom 20px
  .w-sidebar-main
    padding-top 16px
    .w-app-title-name
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin 0px 0 0 16px
    .w-app-list-wrap
      position relative
      margin-top 10px
      margin-left 8px
      ul li
        // cursor pointer
        // float left
        // width 30%
        // text-align center
        // height 105px
        // padding-top 30px
        // padding-left 10px
        // padding-right 10px
        // margin 0 35px
        // border 1px solid transparent
        // margin-bottom 14px
        cursor pointer
        float left
        width 150px
        height 164px
        text-align center
        margin 0 7px
        border 1px solid transparent
        margin-bottom 14px
        // &.active
        // border 1px solid #FFA134
        // box-shadow 0px 1px 12px 0px rgba(255, 161, 52, 0.31)
        // border-radius 3px
        // &:last-child
        // .w-app-title
        // color rgba(255, 255, 255, 0.2)
        // .w-app-text
        // color rgba(255, 255, 255, 0.1)
        &:hover
          border 1px solid #FFA134
          box-shadow 0px 1px 12px 0px rgba(255, 161, 52, 0.31)
          border-radius 3px
        .w-app-img
          position relative
          img
            height 42px
            margin-top 38px
          .w-msg-label
            right 20px
            top 2px
        .w-app-title
          font-size 14px
          font-weight 500
          color #FFFFFF
          margin-top 3px
          margin-bottom 3px
        .w-app-text
          font-size 12px
          font-weight 400
          color #A5A5A7
      .w-shadow
        position absolute
        left 0
        bottom 135px
        width 100%
        height 2px
        box-shadow 0px -1px 7px 0px rgba(0, 0, 0, 0.21)
        z-index 11
  .w-used-function
    display flex
    padding 0 22px
    margin-top 20px
    span
      color #ffffff
      font-size 16px
      font-weight 500
    .w-mangage
      margin-left auto
      font-size 12px
      color #FFA134
      cursor pointer
  .w-used-wrap
    margin 0 22px
    margin-top 35px
    border 1px solid rgba(255, 255, 255, 0.1)
    border-radius 3px
    margin-bottom 40px
    .w-used-item
      display flex
      height 92px
      align-items center
      margin 0 25px
      border-bottom 1px solid rgba(255, 255, 255, 0.1)
      cursor pointer
      &:hover span
        color #FFA134
      &:last-child
        border-bottom none
      span
        color #ffffff
        vertical-align middle
        font-size 24px
        margin-right 20px
      .w-item-name
        .w-p1
          font-size 14px
          font-weight 500
          color #FFFFFF
        .w-p2
          font-size 12px
          font-weight 400
          color #A5A5A7
      .w-add-text
        color #fff
        font-size 14px
    .w-used-item:hover .w-add-text
      color #FFA134
    .w-used-item:hover .w-p1
      color #FFA134
.w-is-not-data
  padding 30px 0
  border 1px solid rgba(255, 255, 255, 0.1)
  margin 20px
  margin-bottom 50px
</style>
