<template>
  <div class="w-work-wrap">
    <div class="w-work-href">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          class="link-item"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <span
          tag="span"
          class="link-item"
          :to="{ path: '/implement' }"
        >工单管理</span>
        <i
          v-if="$route.meta.title === '工单提交' || $route.meta.title === '表单提交'"
          class="el-icon-arrow-right"
        ></i>
        <router-link
          v-if="$route.meta.title === '工单提交' || $route.meta.title === '表单提交'"
          tag="span"
          class="link-item"
          :to="{ path: '/workOrderCenter' }"
        >工单中心</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">{{ $route.meta.title }}</span>
      </div>
      <div class="select-right">
        <div
          class="sub-order"
          v-if="routePath == '/workOrderCenter'"
          @click="toPath(3)"
        >
          <i class="icon-work-submit"></i>
          提交工单
        </div>

        <div
          class="exp-order"
          v-if="routePath == '/workOrderCenter'"
          @click="exportWork"
        >
          <i class="icon-work-export"></i>
          导出
        </div>
        <div
          class="w-input-sty project-select"
          v-if="routePath === '/teamManagement'"
        >
          <div
            class="new-team-btn"
            @click="creatWorkgroup"
          >新建工作组</div>
        </div>
        <div
          class="w-input-sty project-select"
          v-if="routePath === '/workSetting'"
        >
          <div
            class="new-team-btn"
            @click="creatProcess"
          >新建流程</div>
        </div>
        <div
          class="form-submit-btn"
          v-if="routePath === '/formSubmit' && type === ''"
        >
          <div
            :class="[submitPermission ? 'new-team-btn' : 'not-team-btn']"
            @click="commitClick()"
          >提交</div>
          <div
            class="new-team-btn2"
            @click="resetClick()"
          >重置</div>
        </div>
        <div
          v-show="!isShow.includes(routePath)"
          class="w-input-sty project-select"
          :class="routePath === '/workSetting' ? 'ml-16' : ''"
        >
          <el-select
            popper-class="w-block-select-down yellow-select-panel"
            class="input-solid"
            v-model="filtroProject"
            size="small"
            placeholder="请选择"
            filterable
            @change="changeProject"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      class="w-work-tabs"
      :class="[positonTop ? 'positonTop' : '']"
    >
      <div
        class="w-work-modular-wrap cursor"
        @click="clickDrawer()"
      >
        <span class="icon-side-hide"></span>工单管理
      </div>
      <ul class="tab-ul">
        <li
          @click="toPath(0)"
          :class="[routePath === '/workOrderCenter' || routePath === '/workSubmit' ? 'router-link-exact-active' : '' || routePath === '/formSubmit' ? 'router-link-exact-active' : '']"
        >
          <p>工单中心</p>
        </li>
        <li
          @click="toPath(1)"
          :class="[routePath === '/workSetting' ? 'router-link-exact-active' : '']"
        >
          <p>工单设置</p>
        </li>
        <li
          @click="toPath(2)"
          :class="[routePath === '/teamManagement' ? 'router-link-exact-active' : '']"
        >
          <p>工作组管理</p>
        </li>
      </ul>
    </div>
    <el-drawer
      :size="isPx"
      :with-header="false"
      :visible.sync="drawer"
      direction="ltr"
      ref="drawer"
    >
      <Sidebar></Sidebar>
    </el-drawer>
    <div v-if="filtroProject > 0 && hasPower">
      <router-view
        ref="workCenter"
        class="w-work-content"
        :projectId="filtroProject"
        :projectName="projectName"
        :projectOptions="projectOptions"
        @onChangeProject="onChangeProject"
        @submitPermissionEmit="submitPermissionEmit"
      />
    </div>
    <div
      class="jurisdiction-wrap"
      v-else
    >
      <img
        src="~@/assets/images/Jurisdiction.png"
        alt=""
      >
      <template v-if="!hasPower">
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </template>
      <template v-else>
        <p>暂无数据</p>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Sidebar from '@/components/Sidebar.vue'
import { mapGetters } from 'vuex'
import { powerAuth } from '@/utils'
export default {
  components: {
    Sidebar
  },
  data () {
    return {
      hasPower: true,
      filtroProject: 0,
      projectOptions: [],
      drawer: false,
      routePath: '',
      isPx: '516px',
      positonTop: false,
      projectName: '',
      isShow: ['/teamManagement', '/formSubmit'],
      type: '',
      submitPermission: true,
      userPowerCenter: null,
      userPowerSetting: null,
      userPowerGroup: null,
      userPowerPublish: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.routePath = newVal
      this.drawer = false
    },
    '$route' (o, n) {
      this.type = o.query.type || ''
    }
  },
  created () {
    this.routePath = this.$route.path
    this.type = this.$route.query.type || ''
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.getDataProjList()
    window.addEventListener('scroll', this.handleScroll)
    this.userPowerCenter = this.user.confIds.find(item => {
      return item.id === 232
    })
    this.userPowerSetting = this.user.confIds.find(item => {
      return item.id === 233
    })
    this.userPowerGroup = this.user.confIds.find(item => {
      return item.id === 234
    })
    this.userPowerPublish = this.user.confIds.find(item => {
      return item.id === 235
    })
    let key = 0
    switch (this.$route.path) {
      case '/workOrderCenter':
        key = 0
        if (this.checkPower(0) !== false) {
          this.hasPower = true
        } else {
          const otherList = this.user.confIds.filter(item => {
            return [235, 236, 237, 238, 239].indexOf(item.id) !== -1
          })
          console.log('otherList', otherList)
          if (otherList && otherList.length > 0) {
            this.hasPower = true
          } else {
            this.hasPower = false
          }
        }
        break
      case '/workSetting':
        key = 1
        this.hasPower = this.checkPower(1) !== false
        break
      case '/teamManagement':
        key = 2
        this.hasPower = this.checkPower(2) !== false
        break
      case '/workSubmit':
        key = 3
        this.hasPower = this.checkPower(3) !== false
        break
      default:
        this.hasPower = this.checkPower(0) !== false
        key = 0
        break
    }
    if (!this.hasPower) {
      this.$message({
        type: 'warning',
        message: '暂无' + [
          '工单中心',
          '工单设置',
          '工作组管理',
          '提交工单'
        ][key] + '权限，如需开通权限，请联系管理员开通'
      })
    }
  },
  methods: {
    onChangeProject (e) {
      if (typeof e === 'string') {
        e = parseInt(e)
      }
      this.$nextTick(() => {
        this.filtroProject = parseInt(e)
        this.changeProject(e)
      })
    },
    creatWorkgroup () {
      // 防止调用同名方法
      if (this.routePath === '/teamManagement') { this.$refs.workCenter.showCreatForm() }
    },
    exportWork () {
      // 防止调用同名方法
      if (this.routePath === '/workOrderCenter') { this.$refs.workCenter.exportWork() }
    },
    changeProject (val) {
      const params = {
        projectId: val
      }
      console.log(val)
      const obj = this.projectOptions.find(item => {
        console.log(item.id, val)
        return item.id === val
      })
      this.projectName = obj.projectName
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getDataProjList () { // select项目列表
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        console.log('执行getDataProjList')
        this.projectOptions = res.data.data
        this.getProjectIdFunc().then((res) => {
          this.filtroProject = res.data.data.projectId
          this.projectName = res.data.data.projectName
          // this.$router.push({
          //   query: { projectId: this.filtroProject }
          // })
        })
        this.loading = false
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    checkPower (key) {
      const powerList = []
      powerList[0] = this.userPowerCenter
      powerList[1] = this.userPowerSetting
      powerList[2] = this.userPowerGroup
      powerList[3] = this.userPowerPublish
      if (!powerList[key]) {
        return false
      }
    },
    toPath (key) {
      const list = [
        '/workOrderCenter',
        '/workSetting',
        '/teamManagement',
        '/workSubmit'
      ]
      let hasPower = false
      if (key === 0) {
        if (this.checkPower(0) !== false) {
          hasPower = true
        } else {
          const otherList = this.user.confIds.filter(item => {
            return [235, 236, 237, 238, 239].indexOf(item.id) !== -1
          })
          if (otherList && otherList.length) {
            hasPower = true
          } else {
            hasPower = false
          }
        }
      } else {
        hasPower = this.checkPower(key) !== false
      }
      if (!hasPower) {
        this.$message({
          type: 'warning',
          message: '暂无' + [
            '工单中心',
            '工单设置',
            '工作组管理',
            '提交工单'
          ][key] + '权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      localStorage.removeItem('saveScreen')
      this.$router.push({
        path: list[key]
      })
    },
    handleScroll (e) {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 88) {
        this.positonTop = true
      } else {
        this.positonTop = false
      }
    },
    clickDrawer () {
      this.drawer = true
    },
    creatProcess () { // 新建流程
      if (!powerAuth(233, 'create')) {
        this.hasnotPermission()
        return false
      }
      const basicFormHref = this.$router.resolve({
        path: '/basicForm',
        query: {
          active: 0,
          projectId: this.filtroProject
        }
      })
      window.open('/' + basicFormHref.href, '_blank')
    },
    commitClick () {
      if (!this.submitPermission) {
        return false
      }
      this.$refs.workCenter.commitRefFunc()
    },
    resetClick () {
      if (!this.submitPermission) {
        return false
      }
      this.$refs.workCenter.resetRefFunc()
    },
    submitPermissionEmit () {
      this.submitPermission = false
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-work-wrap
  .w-work-href
    height 64px
    display flex
    align-items center
    padding 0 24px
    padding-left 204px
    .select-right
      margin-left auto
      display flex
      align-items center
      .sub-order
        color #fff
        margin 0 20px
        cursor pointer
      .exp-order
        color #fff
        margin 0 40px 0 20px
        cursor pointer
      .ml-16
        margin-left 16px
  .w-work-tabs
    position absolute
    left 0
    top 140px
    width 180px
    height calc(100% - 122px)
    z-index 11
    .w-work-modular-wrap
      height 34px
      line-height 33px
      font-size 16px
      font-weight 500
      color #FFFFFF
      background-color #272930
      // box-shadow 32px 0px 13px 0px rgba(18, 19, 25, 0.5)
      .icon-side-hide
        width 40px
        text-align center
        color #fff
        background-color rgba(255, 255, 255, 0.1)
        border-right 1px solid rgba(255, 255, 255, 0.2)
        margin-right 20px
    .tab-ul
      height calc(100% - 55px)
      background-color #23252B
      margin-top 13px
      padding-top 5px
      >li
        position relative
        height 40px
        line-height 40px
        // text-align center
        padding-left 20px
        cursor pointer
        box-sizing border-box
        border-left 3px solid #23252B
        margin 5px 0
        .el-icon-arrow-down
          top 22px
          right 15px
          font-size 12px
          color #fff
        &.router-link-exact-active
          background-color rgba(255, 255, 255, 0.07)
          border-left 3px solid #FFA134
          span, p
            color #ffffff
        &:hover
          background-color rgba(255, 255, 255, 0.07)
          border-left 3px solid #FFA134
          span, p
            color #fff
        span
          font-size 24px
          margin-top 26px
          margin-bottom 5px
          color #A4A4A6
        p
          height 14px
          font-size 14px
          font-weight 400
          color #A5A5A7
.w-work-wrap
  .positonTop
    position fixed
    left 0
    top 8px !important
    height 100%
    z-index 11
    ul
      min-height 100%
      background-color #23252B
      margin-top 10px
.submenu-menu
  ul li
    height 40px
    line-height 40px
    color rgba(165, 165, 167, 1)
    font-size 14px
    padding-left 30px
    cursor pointer
    border-left 3px solid transparent
    &:hover
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
    &.router-link-exact-active
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
      color #fff
.w-work-wrap .w-work-tabs .tab-ul .isChecked
  p
    color #fff
  .el-icon-arrow-down
    transform rotateZ(180deg)
.form-submit-btn
  display flex
.new-team-btn
  width 140px
  height 36px
  background #FFA134
  font-size 16px
  border-radius 2px
  color #fff
  line-height 36px
  text-align center
  cursor pointer
.not-team-btn
  width 140px
  height 36px
  background #81591b
  font-size 16px
  border-radius 2px
  color rgba(255, 255, 255, 0.5)
  line-height 36px
  text-align center
  cursor pointer
.new-team-btn2
  width 140px
  height 36px
  border 1px solid #FFA134
  font-size 16px
  border-radius 2px
  color #FFA134
  line-height 36px
  text-align center
  cursor pointer
  margin-left 16px
.jurisdiction-wrap
  margin-left 204px
</style>
